import axios from 'axios';
import config from './config';


const instance = axios.create({
  baseURL: config.apiUrl,
  headers: {
    'Content-Type': 'application/json'
  }
});

instance.interceptors.request.use(function (configure) {
  let authUid;
  try {authUid = localStorage.getItem('authUid')}
  catch (e) {authUid = ''}

  configure.headers.authUID = authUid;
  return configure;
});

instance.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  return Promise.reject(error.response);
});

export default instance;