export interface iEmployerInvitation {
  id: number;
  vacancy_name: string;
  city: number;
  publication_date: string;
  invitation: [
    {
      id: number;
      seen: boolean;
      invitation_message: string | null;
      created_at: string;
      contact_phone_number: string;
      employer: number;
      cv: {
        id: number;
        full_name: string;
        picture: string | null;
      }
    }
  ]
}

export interface iEmployerInvitations {
  singleEmployerInvitation: iEmployerInvitation;
  employerInvitations: iEmployerInvitation[];
  loading: boolean;
  error: any;
}

export interface iError {
  error: any
}

export enum eInvitations {
  FETCH_EMPLOYER_INVITATIONS_REQUEST = 'FETCH_EMPLOYER_INVITATIONS_REQUEST',
  FETCH_EMPLOYER_INVITATIONS_SUCCESS = 'FETCH_EMPLOYER_INVITATIONS_SUCCESS',
  FETCH_EMPLOYER_INVITATIONS_FAILURE = 'FETCH_EMPLOYER_INVITATIONS_FAILURE',

  FETCH_SINGLE_EMPLOYER_INVITATION_REQUEST = 'FETCH_SINGLE_EMPLOYER_INVITATION_REQUEST',
  FETCH_SINGLE_EMPLOYER_INVITATION_SUCCESS = 'FETCH_SINGLE_EMPLOYER_INVITATION_SUCCESS',
  FETCH_SINGLE_EMPLOYER_INVITATION_FAILURE = 'FETCH_SINGLE_EMPLOYER_INVITATION_FAILURE',

  SEND_EMPLOYER_INVITATION_SEEN_REQUEST = 'SEND_EMPLOYER_INVITATION_SEEN_REQUEST',
  SEND_EMPLOYER_INVITATION_SEEN_SUCCESS = 'SEND_EMPLOYER_INVITATION_SEEN_SUCCESS',
  SEND_EMPLOYER_INVITATION_SEEN_FAILURE = 'SEND_EMPLOYER_INVITATION_SEEN_FAILURE',
}

interface fetchEmployerInvitationsRequest {
  type: eInvitations.FETCH_EMPLOYER_INVITATIONS_REQUEST
}

interface fetchEmployerInvitationsSuccess {
  type: eInvitations.FETCH_EMPLOYER_INVITATIONS_SUCCESS,
  payload: iEmployerInvitation[]
}

interface fetchEmployerInvitationsFailure {
  type: eInvitations.FETCH_EMPLOYER_INVITATIONS_FAILURE,
  payload: iError
}

interface fetchSingleEmployerInvitationRequest {
  type: eInvitations.FETCH_SINGLE_EMPLOYER_INVITATION_REQUEST
}

interface fetchSingleEmployerInvitationSuccess {
  type: eInvitations.FETCH_SINGLE_EMPLOYER_INVITATION_SUCCESS,
  payload: iEmployerInvitation
}

interface fetchSingleEmployerInvitationFailure {
  type: eInvitations.FETCH_SINGLE_EMPLOYER_INVITATION_FAILURE,
  payload: iError
}

interface sendEmployerInvitationSeenRequest {
  type: eInvitations.SEND_EMPLOYER_INVITATION_SEEN_REQUEST
}

interface sendEmployerInvitationSeenSuccess {
  type: eInvitations.SEND_EMPLOYER_INVITATION_SEEN_SUCCESS
}

interface sendEmployerInvitationSeenFailure {
  type: eInvitations.SEND_EMPLOYER_INVITATION_SEEN_FAILURE,
  payload: iError
}

export type EmployerInvitationsActions =
  fetchEmployerInvitationsRequest |
  fetchEmployerInvitationsSuccess |
  fetchEmployerInvitationsFailure |
  fetchSingleEmployerInvitationRequest |
  fetchSingleEmployerInvitationSuccess |
  fetchSingleEmployerInvitationFailure |
  sendEmployerInvitationSeenRequest |
  sendEmployerInvitationSeenSuccess |
  sendEmployerInvitationSeenFailure