export interface iCompanyDetail {
  active_vacancies_amount: number;
  address: string;
  // contact_phone_number: string;
  id: number;
  logo: string;
  organization_branches: any[];
  organization_city: number;
  organization_description: string;
  organization_name: string;
  organization_professional_field: string;
  site_address: string;
  vacancies: {
    city: {
      id: number;
      name: string
    };
    conditions: string;
    description: string;
    employer_id: number;
    employment_type_id: number;
    experience: null;
    experience_amount_id: {
      value: string;
      id: number;
    };
    experience_amount: {
      value: string;
      id: number;
    };
    id: number;
    is_closed: boolean;
    is_moderated: boolean;
    negotiated_salary: boolean;
    on_editing: boolean;
    position_id: number;
    publication_date: string;
    requirements: string;
    responsibilities: string;
    salary_currency: string;
    salary_from: number;
    salary_to: number;
    schedule_id: number;
    vacancy_name: string;
  }[]
}

export interface iOrganization {
  id: number;
  organization_name: string;
  organization_description: string;
  organization_city: number;
  organization_professional_field: number;
  address: string;
  site_address: string;
  logo: string;
  is_moderated: boolean;
}

export interface iError {
  error: any
}

export enum eOrganization {
  CREATE_ORGANIZATION_REQUEST = 'CREATE_ORGANIZATION_REQUEST',
  CREATE_ORGANIZATION_SUCCESS = 'CREATE_ORGANIZATION_SUCCESS',
  CREATE_ORGANIZATION_FAILURE = 'CREATE_ORGANIZATION_FAILURE',

  FETCH_ORGANIZATION_REQUEST = 'FETCH_ORGANIZATION_REQUEST',
  FETCH_ORGANIZATION_SUCCESS = 'FETCH_ORGANIZATION_SUCCESS',
  FETCH_ORGANIZATION_FAILURE = 'FETCH_ORGANIZATION_FAILURE',

  UPDATE_ORGANIZATION_REQUEST = 'UPDATE_ORGANIZATION_REQUEST',
  UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS',
  UPDATE_ORGANIZATION_FAILURE = 'UPDATE_ORGANIZATION_FAILURE',
}

interface createOrganizationRequest {
  type: eOrganization.CREATE_ORGANIZATION_REQUEST
}

interface createOrganizationSuccess {
  type: eOrganization.CREATE_ORGANIZATION_SUCCESS
}

interface createOrganizationFailure {
  type: eOrganization.CREATE_ORGANIZATION_FAILURE,
  payload: iError
}

interface fetchOrganizationRequest {
  type: eOrganization.FETCH_ORGANIZATION_REQUEST
}

interface fetchOrganizationSuccess {
  type: eOrganization.FETCH_ORGANIZATION_SUCCESS,
  payload: iOrganization
}

interface fetchOrganizationFailure {
  type: eOrganization.FETCH_ORGANIZATION_FAILURE,
  payload: iError
}

interface updateOrganizationRequest {
  type: eOrganization.UPDATE_ORGANIZATION_REQUEST
}

interface updateOrganizationSuccess {
  type: eOrganization.UPDATE_ORGANIZATION_SUCCESS
}

interface updateOrganizationFailure {
  type: eOrganization.UPDATE_ORGANIZATION_FAILURE,
  payload: iError
}

export type OrganizationActions =
  createOrganizationRequest |
  createOrganizationSuccess |
  createOrganizationFailure |
  fetchOrganizationRequest |
  fetchOrganizationSuccess |
  fetchOrganizationFailure |
  updateOrganizationRequest |
  updateOrganizationSuccess |
  updateOrganizationFailure