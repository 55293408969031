import {initializeApp} from "firebase/app";
// import {getFirestore, collection, doc, getDocs, getDoc, addDoc, updateDoc, deleteDoc} from "firebase/firestore";
// import {
//   getAuth,
//   updateProfile,
//   signInWithEmailAndPassword,
//   createUserWithEmailAndPassword,
//   signOut,
//   onAuthStateChanged,
//   GoogleAuthProvider,
//   signInWithPopup
// } from "firebase/auth";
import {getAnalytics, logEvent, setUserProperties} from "firebase/analytics";

// const firebaseConfig = {
//   apiKey: "AIzaSyBRnWt387TrtGTNoY2HsvFY_pn1knHfdkw",
//   authDomain: "shop-kb.firebaseapp.com",
//   databaseURL: 'https://shop-kb.firebaseapp.com',
//   projectId: "shop-kb",
//   storageBucket: "shop-kb.appspot.com",
//   messagingSenderId: "829551647511",
//   appId: "1:829551647511:web:f9f83b83ba30666a9eb552",
//   measurementId: "G-GD7MGLL49J"
// };

const firebaseConfig = {
  apiKey: "AIzaSyBx30qKJ_3TWZzGYYQhfWqWBxYUyNdmy7A",
  authDomain: "joopbar.firebaseapp.com",
  projectId: "joopbar",
  storageBucket: "joopbar.appspot.com",
  messagingSenderId: "213308538787",
  appId: "1:213308538787:web:5d45dcbe82d03650d4a416",
  measurementId: "G-W2RG406Y9Q"
};

initializeApp(firebaseConfig);
// const app = initializeApp(firebaseConfig);
// const db = getFirestore(app);
// const auth = getAuth();
// const googleProvider = new GoogleAuthProvider();
const analytics = getAnalytics();

// auth.languageCode = 'ru';

// =========================================================
// Custom features

const errors = [
  {
    originalCode: 'auth/user-not-found',
    custom: {code: 401, message: 'Неправильный Email или пароль.'}
  },
  {
    originalCode: 'auth/wrong-password',
    custom: {code: 401, message: 'Неправильный Email или пароль.'}
  },
  {
    originalCode: 'permission-denied',
    custom: {code: 403, message: 'Отсутствуют права или их недостаточно.'}
  },
];

// function convertError(error) {
//   // console.log(error.code);
//   // console.log(error.message);
//   const err = errors.find(el => el.originalCode === error.code);
//   if (err) {
//     // console.log(err.custom);
//     return err.custom;
//   }
//   return {code: error.code, message: error.message};
// }

// function expandNesting(doc) {
//   if (!doc.exists) return doc.id || doc;
//
//   const data = doc.data();
//   Object.keys(data).forEach(key => {
//     if (typeof data[key] === 'object' && !data[key].id) {
//       if (Array.isArray(data[key])) data[key] = data[key].map(el => expandNesting(el));
//       else Object.keys(data[key]).forEach(k => {
//         data[key][k] = expandNesting(data[key][k]);
//       });
//     } else data[key] = expandNesting(data[key]);
//   });
//
//   return {...data, id: doc.id};
// }

// =========================================================
// Auth methods

// function signInByEmail(email, password) {
//   return new Promise((resolve, reject) => {
//     signInWithEmailAndPassword(auth, email, password)
//       .then(userCredential => {
//         resolve(userCredential.user);
//       })
//       .catch(error => {
//         reject(convertError(error));
//       });
//   });
// }

// function signInByGoogle() {
//   return new Promise((resolve, reject) => {
//     signInWithPopup(auth, googleProvider)
//       .then(result => {
//         // This gives you a Google Access Token. You can use it to access the Google API.
//         // const credential = GoogleAuthProvider.credentialFromResult(result);
//         // const token = credential.accessToken;
//         resolve(result.user);
//       }).catch((error) => {
//       // const credential = GoogleAuthProvider.credentialFromError(error);
//       reject(error);
//     });
//   });
// }

// function registerByEmail(email, password) {
//   return new Promise((resolve, reject) => {
//     createUserWithEmailAndPassword(auth, email, password)
//       .then(userCredential => {
//         updateUser({displayName: email.substring(0, email.indexOf('@'))})
//           .then(() => {
//             resolve(userCredential.user);
//           })
//           .catch(error => {
//             reject(convertError(error));
//           });
//       })
//       .catch(error => {
//         reject(convertError(error));
//       });
//   });
// }

// function updateUser(data) {
//   return new Promise((resolve, reject) => {
//     updateProfile(auth.currentUser, data)
//       .then(() => {
//         resolve();
//       })
//       .catch(error => {
//         reject(convertError(error));
//       });
//   });
// }

// function logOut() {
//   return new Promise((resolve, reject) => {
//     signOut(auth).then(() => {
//       resolve();
//     }).catch((error) => {
//       reject(convertError(error));
//     });
//   });
// }

// function authState() {
//   return new Promise((resolve, reject) => {
//     onAuthStateChanged(auth, user => {
//       if (user) resolve(user);
//       else reject();
//     });
//   });
// }

// function getCurrentUser() {
//   return auth.currentUser;
// }

// =========================================================
// Firestore methods

// function all(path) {
//   const collectionRef = collection(db, path);
//
//   return new Promise((resolve, reject) => {
//     getDocs(collectionRef).then(
//       response => {
//         resolve({data: response.docs.map(_doc => ({...expandNesting(_doc)}))});
//       },
//       error => {
//         reject(convertError(error));
//       }
//     )
//   });
// }

// function create(path, data) {
//   const collectionRef = collection(db, path);
//
//   return new Promise((resolve, reject) => {
//     addDoc(collectionRef, data).then(
//       response => {
//         resolve(response);
//       },
//       error => {
//         reject(convertError(error));
//       }
//     )
//   });
// }

// function read(path, id) {
//   const docRef = doc(db, path, id);
//
//   return new Promise((resolve, reject) => {
//     getDoc(docRef).then(
//       response => {
//         resolve({data: {...expandNesting(response)}});
//       },
//       error => {
//         reject(convertError(error));
//       }
//     )
//   });
// }

// function update(path, id, data) {
//   const docRef = doc(db, path, id);
//
//   return new Promise((resolve, reject) => {
//     updateDoc(docRef, data).then(
//       response => {
//         resolve(response);
//       },
//       error => {
//         reject(convertError(error));
//       }
//     )
//   });
// }

// function remove(path, id) {
//   const docRef = doc(db, path, id);
//
//   return new Promise((resolve, reject) => {
//     deleteDoc(docRef).then(
//       response => {
//         resolve(response);
//       },
//       error => {
//         reject(convertError(error));
//       }
//     )
//   });
// }

// function getDocRef(path, id) {
//   return doc(db, path, id);
// }

// =========================================================
// Analytics methods

function event(eventType, data) {
  return logEvent(analytics, eventType, data);
}

function setUserProps(data) {
  setUserProperties(analytics, data);
}

// =========================================================
// Exports

// export const fAuth = {signInByEmail, registerByEmail, logOut, getCurrentUser, authState, signInByGoogle, updateUser};
// export const firestore = {all, create, read, update, remove, getDocRef};
export const fAnalytics = {event, setUserProps};
