
export interface iVacancy {
  id: number,
  is_applied: boolean,
  position: {
    id: number,
    name: string
  },
  vacancy_name: string,
  city: {
    id: number,
    name: string,
  },
  employer: {
    id: number,
    position: string,
    user: number,
    organization: {
      id: number,
      organization_name: string,
      organization_description: string,
      organization_city: number,
      logo?: string,
      active_vacancies_amount?: number,
    }
  },
  description: string,
  salary: number,
  schedule: {
    id: number,
    name: string
  },
  employment_type: {
    id: number,
    name: string
  },
  professional_fields: iProfessionalField[],
  experience: boolean,
  experience_amount: {
    id: number;
    value: string;
  }
  responsibilities: string,
  requirements: string,
  conditions: string,
  responses_amount: number,
  is_moderated: boolean,
  on_editing: boolean,
  publication_date: any,
  salary_from: number,
  salary_to: number,
  new_responses_amount: number,
  invitations_amount: number,
  negotiated_salary: boolean,
  recommended_cvs_amount: number,
  is_closed: boolean
}

interface iProfessionalField {
  id: number;
  name: string;
}

export interface iVacanciesFilters {
  filterSearchValue: string;
  city: string;
  schedule: string;
  position: string;
  salary: string;
  search: string;
}

export interface iVacancies {
  vacancy: iVacancy;
  vacancies: iVacancy[];
  vacanciesFilters: iVacanciesFilters;
  loading: boolean;
  error: null;
}

export interface iError {
  error: any
}

export enum eVacancies {
  FETCH_VACANCIES_BY_AUTHUID_REQUEST = 'FETCH_VACANCIES_BY_AUTHUID_REQUEST',
  FETCH_VACANCIES_BY_AUTHUID_SUCCESS = 'FETCH_VACANCIES_BY_AUTHUID_SUCCESS',
  FETCH_VACANCIES_BY_AUTHUID_FAILURE = 'FETCH_VACANCIES_BY_AUTHUID_FAILURE',

  FETCH_VACANCIES_REQUEST = 'FETCH_VACANCIES_REQUEST',
  FETCH_VACANCIES_SUCCESS = 'FETCH_VACANCIES_SUCCESS',
  FETCH_VACANCIES_FAILURE = 'FETCH_VACANCIES_FAILURE',
  RESET_VACANCIES = 'RESET_VACANCIES',

  FETCH_VACANCY_BY_ID_REQUEST = 'FETCH_VACANCY_BY_ID_REQUEST',
  FETCH_VACANCY_BY_ID_SUCCESS = 'FETCH_VACANCY_BY_ID_SUCCESS',
  FETCH_VACANCY_BY_ID_FAILURE = 'FETCH_VACANCY_BY_ID_FAILURE',

  CREATE_VACANCY_REQUEST = 'CREATE_VACANCY_REQUEST',
  CREATE_VACANCY_SUCCESS = 'CREATE_VACANCY_SUCCESS',
  CREATE_VACANCY_FAILURE = 'CREATE_VACANCY_FAILURE',

  UPDATE_VACANCY_REQUEST = 'UPDATE_VACANCY_REQUEST',
  UPDATE_VACANCY_SUCCESS = 'UPDATE_VACANCY_SUCCESS',
  UPDATE_VACANCY_FAILURE = 'UPDATE_VACANCY_FAILURE',

  APPLY_VACANCY_REQUEST = 'APPLY_VACANCY_REQUEST',
  APPLY_VACANCY_SUCCESS = 'APPLY_VACANCY_SUCCESS',
  APPLY_VACANCY_FAILURE = 'APPLY_VACANCY_FAILURE',

  DELETE_VACANCY_REQUEST = 'DELETE_VACANCY_REQUEST',
  DELETE_VACANCY_SUCCESS = 'DELETE_VACANCY_SUCCESS',
  DELETE_VACANCY_FAILURE = 'DELETE_VACANCY_FAILURE',

  SEND_VACANCY_RESPONSE_SEEN_REQUEST = 'SEND_VACANCY_RESPONSE_SEEN_REQUEST',
  SEND_VACANCY_RESPONSE_SEEN_SUCCESS = 'SEND_VACANCY_RESPONSE_SEEN_SUCCESS',
  SEND_VACANCY_RESPONSE_SEEN_FAILURE = 'SEND_VACANCY_RESPONSE_SEEN_FAILURE',

  SET_DOWNLOADED_VACANCIES_SUCCESS = 'SET_DOWNLOADED_VACANCIES_SUCCESS',

  SET_VACANCIES_FILTERS_SUCCESS = 'SET_VACANCIES_FILTERS_SUCCESS'
}

interface fetchVacanciesByAuthUidRequest {
  type: eVacancies.FETCH_VACANCIES_BY_AUTHUID_REQUEST
}

interface fetchVacanciesByAuthUidSuccess {
  type: eVacancies.FETCH_VACANCIES_BY_AUTHUID_SUCCESS,
  payload: iVacancy[]
}

interface fetchVacanciesByAuthUidFailure {
  type: eVacancies.FETCH_VACANCIES_BY_AUTHUID_FAILURE,
  payload: iError
}

interface fetchVacanciesRequest {
  type: eVacancies.FETCH_VACANCIES_REQUEST
}

interface fetchVacanciesSuccess {
  type: eVacancies.FETCH_VACANCIES_SUCCESS,
  payload: iVacancy[]
}

interface fetchVacanciesFailure {
  type: eVacancies.FETCH_VACANCIES_FAILURE,
  payload: iError
}

interface resetVAcancies {
  type: eVacancies.RESET_VACANCIES,
  payload: []
}

interface fetchVacancyByIdRequest {
  type: eVacancies.FETCH_VACANCY_BY_ID_REQUEST
}

interface fetchVacancyByIdSuccess {
  type: eVacancies.FETCH_VACANCY_BY_ID_SUCCESS,
  payload: iVacancy
}

interface fetchVacancyByIdFailure {
  type: eVacancies.FETCH_VACANCY_BY_ID_FAILURE,
  payload: iError
}

interface createVacancyRequest {
  type: eVacancies.CREATE_VACANCY_REQUEST
}

interface createVacancySuccess {
  type: eVacancies.CREATE_VACANCY_SUCCESS
}

interface createVacancyFailure {
  type: eVacancies.CREATE_VACANCY_FAILURE,
  payload: iError
}

interface updateVacancyRequest {
  type: eVacancies.UPDATE_VACANCY_REQUEST
}

interface updateVacancySuccess {
  type: eVacancies.UPDATE_VACANCY_SUCCESS
}

interface updateVacancyFailure {
  type: eVacancies.UPDATE_VACANCY_FAILURE,
  payload: iError
}

interface applyVacancyRequest {
  type: eVacancies.APPLY_VACANCY_REQUEST
}

interface applyVacancySuccess {
  type: eVacancies.APPLY_VACANCY_SUCCESS
}

interface applyVacancyFailure {
  type: eVacancies.APPLY_VACANCY_FAILURE,
  payload: iError
}

interface deleteVacancyRequest {
  type: eVacancies.DELETE_VACANCY_REQUEST
}

interface deleteVacancySuccess {
  type: eVacancies.DELETE_VACANCY_SUCCESS
}

interface deleteVacancyFailure {
  type: eVacancies.DELETE_VACANCY_FAILURE,
  payload: iError
}

interface sendVacancyResponseSeenRequest {
  type: eVacancies.SEND_VACANCY_RESPONSE_SEEN_REQUEST
}

interface sendVacancyResponseSeenSuccess {
  type: eVacancies.SEND_VACANCY_RESPONSE_SEEN_SUCCESS
}

interface sendVacancyResponseSeenFailure {
  type: eVacancies.SEND_VACANCY_RESPONSE_SEEN_FAILURE,
  payload: iError
}

interface setDownloadedVacanciesSuccess {
  type: eVacancies.SET_DOWNLOADED_VACANCIES_SUCCESS,
  payload: iVacancy[]
}

interface setVacanciseFiltersSuccess {
  type: eVacancies.SET_VACANCIES_FILTERS_SUCCESS,
  payload: iVacanciesFilters
}

export type VacanciesActions =
  fetchVacanciesByAuthUidRequest |
  fetchVacanciesByAuthUidSuccess |
  fetchVacanciesByAuthUidFailure |
  fetchVacanciesRequest |
  fetchVacanciesSuccess |
  fetchVacanciesFailure |
  resetVAcancies |
  fetchVacancyByIdRequest |
  fetchVacancyByIdSuccess |
  fetchVacancyByIdFailure |
  createVacancyRequest |
  createVacancySuccess |
  createVacancyFailure |
  updateVacancyRequest |
  updateVacancySuccess |
  updateVacancyFailure |
  applyVacancyRequest |
  applyVacancySuccess |
  applyVacancyFailure |
  deleteVacancyRequest |
  deleteVacancySuccess |
  deleteVacancyFailure |
  sendVacancyResponseSeenRequest |
  sendVacancyResponseSeenSuccess |
  sendVacancyResponseSeenFailure |
  setDownloadedVacanciesSuccess |
  setVacanciseFiltersSuccess