import {EKeySkills, iKeySkills, KeySkillsAction} from "./index";

const initialState: iKeySkills = {
  keySkills: [],
  loading: false,
  error: null
}

const reducer = (state = initialState, action: KeySkillsAction) => {
  switch (action.type) {
    case EKeySkills.GET_KEY_SKILLS_LOADING:
      return {...state, loading: true}
    case EKeySkills.GET_KEY_SKILLS_SUCCESS:
      return {...state, loading: false, keySkills: action.payload}
    case EKeySkills.GET_KEY_SKILLS_FAILURE:
      return {...state, loading: false, error: action.payload}
    default:
      return state
  }
}

export default reducer