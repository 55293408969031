const env = process.env.NODE_ENV;

// const apiUrl = env === 'development' ? 'https://agakhan.yorc.org' : '';

const apiUrl = env === 'development' ? 'https://joopbar.org.kg' : '';

const config = {
  apiUrl: apiUrl + '/api'
};

export default config;
