import {Dispatch} from "react";
import {eUserProps, iUserProps} from "./index";

interface iDispatch {
  type: string,
  payload?: any
}

const setUserPropsSuccess = (userProps: iUserProps) => ({
  type: eUserProps.SET_USER_PROPS,
  payload: userProps
})

export const setUserProps = (data: iUserProps) => {
  return (dispatch: Dispatch<iDispatch>) => {
    dispatch(setUserPropsSuccess(data))
  }
}