const env = process.env.NODE_ENV;

const apiUrl = process.env.API_URL || (env === 'development' ? 'https://agakhan.yorc.org' : 'https://joopbar.org.kg');
const mobileAppUrl = process.env.MOBILE_URL || (env === 'development' ? 'https://agakhan-mobile.yorc.org' : 'https://mobile.jia.kg');

const playStoreUrl = process.env.PLAY_STORE_URL || '#';
const appStoreUrl =  process.env.APP_STORE_URL || 'https://apps.apple.com/kg/app/joopbar/id6741681897';

const config = {
  apiUrl: apiUrl + '/api',
  mobileApiUrl: mobileAppUrl + '/api',
  playStoreUrl: playStoreUrl,
  appStoreUrl: appStoreUrl
};

export default config;
