export interface iUserProps {
  language: string;
  user_role: string;
  telegram_name?: string;
  telegram_id?: number;
  company_name?: string;
  phone_number?: string;
  first_name?: string;
  last_name?: string;
  neet?: string;
  gender?: string;
  birth_date?: string;
  joopbar_id?: number;
  full_name?: string;
  city?: string;
  region?: string;
  age?: number;
}

export interface iError {
  error: any
}

export enum eUserProps {
  SET_USER_PROPS = 'SET_USER_PROPS'
}

interface setUserProps {
  type: eUserProps.SET_USER_PROPS,
  payload: iUserProps
}

export type UserPropsActions =
  setUserProps