import {useEffect, useState} from "react";

export default function useAfterRender(cb: () => void) {
  const [state, setState] = useState(false);

  useEffect(() => {
    setState(true);
  }, []);

  useEffect(() => {
    if (state) {
      cb();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);
}