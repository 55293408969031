import React from 'react';

const ApplicantStartPage = React.lazy(() => import('./views/Applicant/ApplicantStartPage'));
const ApplicantFinishPage = React.lazy(() => import('./views/Applicant/ApplicantFinishPage'));
const ResumeIsReady = React.lazy(() => import('./views/Applicant/ResumeIsReady'));
const VacanciesList = React.lazy(() => import('./views/Applicant/VacanciesList'));
const VacancyViewer = React.lazy(() => import('./views/Applicant/VacancyViewer'));
const AppliedVacancy = React.lazy(() => import('./views/Applicant/AppliedVacancy'));
const AppliedVacancyDetail = React.lazy(() => import('./views/Applicant/AppliedVacancyDetail'));
const VacancyCompany = React.lazy(() => import('./views/Applicant/VacancyCompany'));
const Trainings = React.lazy(() => import("./views/Applicant/Trainings"));
const TrainingsDetail = React.lazy(() => import("./views/Applicant/TrainingsDetail"));
const RecommendedVacancies = React.lazy(() => import("./views/Applicant/RecommendedVacancies"));

const EmployerStartPage = React.lazy(() => import('./views/Employer/EmployerStartPage'));
const EmployersVacancies = React.lazy(() => import('./views/Employer/EmployersVacancies'));
const EmployerFinishPage = React.lazy(() => import('./views/Employer/EmployerFinishPage'));
const VacancyIsReady = React.lazy(() => import('./views/Employer/VacancyIsReady'));
const ResumeBase = React.lazy(() => import('./views/Employer/ResumeBase'));
const ResumeViewer = React.lazy(() => import('./views/Employer/ResumeViewer'));
const InviteForAnInterview = React.lazy(() => import('./views/Employer/InviteForAnInterview'));
const ListOfAppliedResume = React.lazy(() => import('./views/Employer/ListOfAppliedResume'));
const AppliedResumeViewer = React.lazy(() => import('./views/Employer/AppliedResumeViewer'));
const ConfirmationOfVacancyDeletion = React.lazy(() => import('./views/Employer/ConfirmationOfVacancyDeletion'));
const CreatingAnOrganization = React.lazy(() => import('./views/Employer/Organization/CreatingAnOrganization'));
const MyOrganization = React.lazy(() => import('./views/Employer/Organization/MyOrganization'));
const MyOrganizationUpdate = React.lazy(() => import('./views/Employer/Organization/MyOrganizationUpdate'));
const CreatingAVacancy = React.lazy(() => import('./views/Employer/CreatingAVacancy'));
// const VacancyUpdate = React.lazy(() => import('./views/Employer/VacancyUpdate'));
const VacancyPreview = React.lazy(() => import('./views/Employer/VacancyPreview'));
const VacanciesResponses = React.lazy(() => import('./views/Employer/Responses/VacanciesResponses'));
const ViewingJobInvitations = React.lazy(() => import('./views/Employer/Responses/ViewingJobInvitations'));
const MyInvitations = React.lazy(() => import('./views/Employer/Responses/MyInvitations'));
const EmployerRecommendations = React.lazy(() => import('./views/Employer/EmployerRecommendations'));
const EmployerRecommendationsList = React.lazy(() => import('./views/Employer/EmployerRecommendationsList'));

const AppBinding = React.lazy(() => import('./views/AppBinding/AppBinding'));

const routes = [
  { path: '/applicant', exact: true, name: 'Applicant start page', element: ApplicantStartPage },
  { path: '/applicant-finish', exact: true, name: 'Applicant finish page', element: ApplicantFinishPage },
  { path: '/resume-is-ready', exact: true, name: 'Resume is ready', element: ResumeIsReady },
  { path: '/vacancies-list', exact: true, name: 'Vacancies list', element: VacanciesList },
  { path: '/vacancies-list/:id', exact: true, name: 'Vacancy viewer', element: VacancyViewer },
  { path: '/vacancies-list/:id/:id', exact: true, name: 'Vacancy viewer', element: VacancyCompany },
  { path: '/applied-vacancy', exact: true, name: 'Applied vacancy', element: AppliedVacancy },
  { path: '/applied-vacancy/:id', exact: true, name: 'Applied vacancy', element: AppliedVacancyDetail },
  { path: '/applied-vacancy/:id/:seen', exact: true, name: 'Applied vacancy', element: AppliedVacancyDetail },
  { path: '/trainings', exact: true, name: 'Trainings', element: Trainings },
  { path: '/trainings/:id', exact: true, name: 'Training detail', element: TrainingsDetail },
  { path: '/recommended-vacancies', exact: true, name: 'Training detail', element: RecommendedVacancies },
  { path: '/recommended-vacancies/:id', exact: true, name: 'Vacancy viewer', element: VacancyViewer },
  { path: '/recommended-vacancies/:id/:id', exact: true, name: 'Vacancy viewer', element: VacancyCompany },

  { path: '/employer', exact: true, name: 'Employer start page', element: EmployerStartPage },
  { path: '/employers-vacancies', exact: true, name: 'Employers vacancies', element: EmployersVacancies },
  { path: '/employer-finish', exact: true, name: 'Employer finish page', element: EmployerFinishPage },
  { path: '/vacancy-is-ready', exact: true, name: 'Vacancy is ready', element: VacancyIsReady },
  { path: '/resume-base', exact: true, name: 'Resume base', element: ResumeBase },
  { path: '/resume-viewer', exact: true, name: 'Resume viewer', element: ResumeViewer },
  { path: '/invite-for-an-interview', exact: true, name: 'Invite for an interview', element: InviteForAnInterview },
  { path: '/list-of-applied-resume', exact: true, name: 'List of applied resume', element: ListOfAppliedResume },
  { path: '/applied-resume-viewer', exact: true, name: 'Applied resume viewer', element: AppliedResumeViewer },
  { path: '/vacancy-delete-confirmation', exact: true, name: 'Vacancy delete confirmation', element: ConfirmationOfVacancyDeletion },
  { path: '/creating-organization', exact: true, name: 'Creating an organization', element: CreatingAnOrganization },
  { path: '/my-organization', exact: true, name: 'Organization update', element: MyOrganization },
  { path: '/my-organization-update', exact: true, name: 'Organization update', element: MyOrganizationUpdate },
  { path: '/creating-vacancy', exact: true, name: 'Creating a vacancy', element: CreatingAVacancy },
  { path: '/vacancy-preview', exact: true, name: 'Vacancy preview', element: VacancyPreview },
  // { path: '/vacancy-update', exact: true, name: 'Vacancy update', element: VacancyUpdate },
  { path: '/vacancies-responses', exact: true, name: 'Vacancies responses', element: VacanciesResponses },
  { path: '/viewing-job-invitations', exact: true, name: 'Viewing job invitations', element: ViewingJobInvitations },
  { path: '/my-invitations', exact: true, name: 'My invitations', element: MyInvitations },
  { path: '/employer-recommendations', exact: true, name: 'Employer recommendations', element: EmployerRecommendations },
  { path: '/employer-recommendations-list', exact: true, name: 'Employer recommendations list', element: EmployerRecommendationsList },

  { path: '/app-binding', exact: true, name: 'App binding form', element: AppBinding },
]

export function getName(pathname: string) {
  return routes.find(el => el.path.includes(pathname))?.name
}

export default routes;