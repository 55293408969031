

export interface iLanguageProficiencyLevel {
  id: number;
  name: string;
}

export interface iLanguageProficiencyLevels {
  language: iLanguageProficiencyLevel[];
  error: any;
  loading: boolean;
}

export enum eLanguageProficiencyLevels {
  GET_LANGUAGE_PROFICIENCY_LEVELS_SUCCESS = "GET_LANGUAGE_PROFICIENCY_LEVELS_SUCCESS",
  GET_LANGUAGE_PROFICIENCY_LEVELS_LOADING = "GET_LANGUAGE_PROFICIENCY_LEVELS_LOADING",
  GET_LANGUAGE_PROFICIENCY_LEVELS_FAILURE = "GET_LANGUAGE_PROFICIENCY_LEVELS_FAILURE"
}

interface fetchLanguageLoading {
  type: eLanguageProficiencyLevels.GET_LANGUAGE_PROFICIENCY_LEVELS_LOADING
}

interface fetchLanguageSuccess {
  type: eLanguageProficiencyLevels.GET_LANGUAGE_PROFICIENCY_LEVELS_SUCCESS;
  payload: iLanguageProficiencyLevel[]
}

interface fetchLanguageFailure {
  type: eLanguageProficiencyLevels.GET_LANGUAGE_PROFICIENCY_LEVELS_FAILURE;
  payload: any
}

export type languageProficiencyLevelsAction = fetchLanguageLoading | fetchLanguageSuccess | fetchLanguageFailure