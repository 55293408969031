import {eWorkExperiences, iWorkExperiences, WorkExperiencesActions} from './index';

const initialState: iWorkExperiences = {
  workExperiences: [],
  loading: false,
  error: null
}

const reducer = (state: iWorkExperiences = initialState, action: WorkExperiencesActions) => {
  switch (action.type) {
    case eWorkExperiences.FETCH_WORK_EXPERIENCES_REQUEST:
      return {...state, loading: true, error: null};
    case eWorkExperiences.FETCH_WORK_EXPERIENCES_SUCCESS:
      return {...state, loading: false, workExperiences: action.payload, error: null};
    case eWorkExperiences.FETCH_WORK_EXPERIENCES_FAILURE:
      return {...state, loading: false, error: action.payload};

    default:
      return {...state};
  }
}

export default reducer;