export interface iLanguage {
  id: number,
  name: string
}

export interface iLanguages {
  languages: iLanguage[];
  error: any;
  loading: boolean;
}

export interface iError {
  error: any
}

export enum eLanguage {
  FETCH_LANGUAGE_REQUEST = 'FETCH_LANGUAGE_REQUEST',
  FETCH_LANGUAGE_SUCCESS = 'FETCH_LANGUAGE_SUCCESS',
  FETCH_LANGUAGE_FAILURE = 'FETCH_LANGUAGE_FAILURE'
}

interface fetchCitiesRequest {
  type: eLanguage.FETCH_LANGUAGE_REQUEST
}

interface fetchCitiesSuccess {
  type: eLanguage.FETCH_LANGUAGE_SUCCESS,
  payload: iLanguage[]
}

interface fetchCitiesFailure {
  type: eLanguage.FETCH_LANGUAGE_FAILURE,
  payload: iError
}

export type LanguageAction =
  fetchCitiesRequest |
  fetchCitiesSuccess |
  fetchCitiesFailure