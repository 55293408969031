export interface iTraining {
  id: number;
  training_name: string;
  training_description: string;
  training_datetime: string;
  organization_name: string;
  address: string;
  contact_data: string;
  training_format: string;
  speaker_name: string;
  registration_link: string;
  meeting_link: string;
  publication_date: string;
}

export interface iTrainingReducer {
  training: iTraining | null;
  trainings: iTraining[];
  loading: boolean;
  error: any
}

export enum eTrainings {
  GET_TRAININGS_SUCCESS = "GET_TRAININGS_SUCCESS",
  GET_TRAININGS_REQUEST = "GET_TRAININGS_REQUEST",
  GET_TRAININGS_FAILURE = "GET_TRAININGS_FAILURE",

  GET_TRAININGS_DETAIL_SUCCESS = "GET_TRAININGS_DETAIL_SUCCESS"
}

interface fetchTrainingsSuccess {
  type: eTrainings.GET_TRAININGS_SUCCESS;
  payload: iTraining[];
}

interface fetchTrainingsRequest {
  type: eTrainings.GET_TRAININGS_REQUEST;
}

interface fetchTrainingsFailure {
  type: eTrainings.GET_TRAININGS_FAILURE;
  payload: any;
}

interface fetchTrainingsDetailSuccess {
  type: eTrainings.GET_TRAININGS_DETAIL_SUCCESS;
  payload: iTraining;
}

export type trainingsAction = fetchTrainingsSuccess
  | fetchTrainingsRequest
  | fetchTrainingsFailure
  | fetchTrainingsDetailSuccess