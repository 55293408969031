import {eUserProps, iUserProps, UserPropsActions} from "./index";

const initialState = {
  language: '',
  user_role: '',
  telegram_name: '',
  telegram_id: 0,
  company_name: '',
  phone_number: '',
  first_name: '',
  last_name: '',
  neet: 'false',
  gender: '',
  birth_date: '',
  joopbar_id: 0,
  full_name: '',
  age: 0
}

const reducer = (state: iUserProps = initialState, action: UserPropsActions) => {
  switch (action.type) {
    case eUserProps.SET_USER_PROPS:
      return {...state, ...action.payload};

    default:
      return {...state};
  }
}

export default reducer;