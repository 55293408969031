import {eInvitations, EmployerInvitationsActions, iEmployerInvitations} from "./index";

const initialState: iEmployerInvitations = {
  singleEmployerInvitation: {
    id: 0,
    vacancy_name: '',
    city: 0,
    publication_date: '',
    invitation: [
      {
        id: 0,
        seen: false,
        invitation_message: '',
        created_at: '',
        contact_phone_number: '',
        employer: 0,
        cv: {
          id: 0,
          full_name: '',
          picture: ''
        }
      }
    ]
  },
  employerInvitations: [],
  loading: false,
  error: null
}

const reducer = (state = initialState, action: EmployerInvitationsActions) => {
  switch (action.type) {
    case eInvitations.FETCH_EMPLOYER_INVITATIONS_REQUEST:
      return {...state, loading: true, error: null};
    case eInvitations.FETCH_EMPLOYER_INVITATIONS_SUCCESS:
      return {...state, loading: false, employerInvitations: action.payload, error: null};
    case eInvitations.FETCH_EMPLOYER_INVITATIONS_FAILURE:
      return {...state, loading: false, error: action.payload};

    case eInvitations.FETCH_SINGLE_EMPLOYER_INVITATION_REQUEST:
      return {...state, loading: true, error: null};
    case eInvitations.FETCH_SINGLE_EMPLOYER_INVITATION_SUCCESS:
      return {...state, loading: false, singleEmployerInvitation: action.payload, error: null};
    case eInvitations.FETCH_SINGLE_EMPLOYER_INVITATION_FAILURE:
      return {...state, loading: false, error: action.payload};

    case eInvitations.SEND_EMPLOYER_INVITATION_SEEN_REQUEST:
      return {...state, loading: true, error: null};
    case eInvitations.SEND_EMPLOYER_INVITATION_SEEN_SUCCESS:
      return {...state, loading: false, error: null};
    case eInvitations.SEND_EMPLOYER_INVITATION_SEEN_FAILURE:
      return {...state, loading: false, error: action.payload};

    default:
      return {...state};
  }
}

export default reducer;