import {eVacancyResponses, iVacancyResponses, VacancyResponsesActions} from "./index";

const initialState: iVacancyResponses = {
  vacancyResponses: [],
  loading: false,
  error: null
}

const reducer = (state: iVacancyResponses = initialState, action: VacancyResponsesActions) => {
  switch (action.type) {
    case eVacancyResponses.FETCH_VACANCY_RESPONSES_REQUEST:
      return {...state, loading: true, error: null};
    case eVacancyResponses.FETCH_VACANCY_RESPONSES_SUCCESS:
      return {...state, loading: false, vacancyResponses: action.payload, error: null};
    case eVacancyResponses.FETCH_VACANCY_RESPONSES_FAILURE:
      return {...state, loading: false, error: action.payload};
    default:
      return {...state};
  }
}

export default reducer;