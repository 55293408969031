export interface iVacancyResponse {
  id: number;
  vacancy: number;
  job_seeker: number;
  cover_letter: string;
  created_at: string;
  seen: boolean;
  invite: boolean;
  job_seeker_cv_id: number;
  job_seeker_full_name: string;
  job_seeker_picture: string;
}

export interface iError {
  error: any
}

export interface iVacancyResponses {
  vacancyResponses: iVacancyResponse[];
  loading: boolean;
  error: null;
}

export enum eVacancyResponses {
  FETCH_VACANCY_RESPONSES_REQUEST = 'FETCH_VACANCY_RESPONSES_REQUEST',
  FETCH_VACANCY_RESPONSES_SUCCESS = 'FETCH_VACANCY_RESPONSES_SUCCESS',
  FETCH_VACANCY_RESPONSES_FAILURE = 'FETCH_VACANCY_RESPONSES_FAILURE',
}

interface fetchVacancyResponsesRequest {
  type: eVacancyResponses.FETCH_VACANCY_RESPONSES_REQUEST
}

interface fetchVacancyResponsesSuccess {
  type: eVacancyResponses.FETCH_VACANCY_RESPONSES_SUCCESS,
  payload: iVacancyResponse[]
}

interface fetchVacancyResponsesFailure {
  type: eVacancyResponses.FETCH_VACANCY_RESPONSES_FAILURE,
  payload: iError
}

export type VacancyResponsesActions =
  fetchVacancyResponsesRequest |
  fetchVacancyResponsesSuccess |
  fetchVacancyResponsesFailure
