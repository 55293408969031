export interface iDegree {
  id: number,
  degree: string
}

export interface iDegrees {
  degrees: iDegree[];
  error: any;
  loading: boolean;
}

export interface iError {
  error: any
}

export enum eDegree {
  FETCH_DEGREE_REQUEST = 'FETCH_DEGREE_REQUEST',
  FETCH_DEGREE_SUCCESS = 'FETCH_DEGREE_SUCCESS',
  FETCH_DEGREE_FAILURE = 'FETCH_DEGREE_FAILURE'
}

interface fetchRequest {
  type: eDegree.FETCH_DEGREE_REQUEST
}

interface fetchSuccess {
  type: eDegree.FETCH_DEGREE_SUCCESS,
  payload: iDegree[]
}

interface fetchFailure {
  type: eDegree.FETCH_DEGREE_FAILURE,
  payload: iError
}

export type DegreeAction =
  fetchRequest |
  fetchSuccess |
  fetchFailure