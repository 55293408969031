export interface iCitizenShip {
  id: number,
  value: string
}

export interface iCitizenShips {
  citizenShips: iCitizenShip[],
  loading: boolean,
  error: any
}

export interface iError {
  error: any
}

export enum eCitizenShips {
  FETCH_CITIZENSHIPS_REQUEST = 'FETCH_CITIZENSHIPS_REQUEST',
  FETCH_CITIZENSHIPS_SUCCESS = 'FETCH_CITIZENSHIPS_SUCCESS',
  FETCH_CITIZENSHIPS_FAILURE = 'FETCH_CITIZENSHIPS_FAILURE'
}

interface fetchCitizenShipsRequest {
  type: eCitizenShips.FETCH_CITIZENSHIPS_REQUEST
}

interface fetchCitizenShipsSuccess {
  type: eCitizenShips.FETCH_CITIZENSHIPS_SUCCESS,
  payload: iCitizenShip[]
}

interface fetchCitizenShipsFailure {
  type: eCitizenShips.FETCH_CITIZENSHIPS_FAILURE,
  payload: iError
}

export type CitizenChipsAction =
  fetchCitizenShipsRequest |
  fetchCitizenShipsSuccess |
  fetchCitizenShipsFailure