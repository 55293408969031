import {eRecommendedVacancies, iRecommendedVacancies, recommendedVacanciesAction} from "./index";

const initialState: iRecommendedVacancies = {
  recommendedVacancies: [],
  error: null,
  loading: false
}

const reducers = (state: iRecommendedVacancies = initialState, action: recommendedVacanciesAction) => {
  switch (action.type) {
    case eRecommendedVacancies.GET_RECOMMENDED_VACANCIES_REQUEST:
      return {...state, loading: true}
    case eRecommendedVacancies.GET_RECOMMENDED_VACANCIES_FAILURE:
      return {...state, loading: false, error: action.payload}
    case eRecommendedVacancies.GET_RECOMMENDED_VACANCIES_SUCCESS:
      return {...state, loading: false, recommendedVacancies: action.payload}
    default:
      return state
  }
}

export default reducers