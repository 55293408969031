import {eProfessionalFields, ProfessionalFieldsAction, iProfessionalFields} from "./index";

const initialState: iProfessionalFields = {
  professionalFields: [],
  loading: false,
  error: null
}

const reducer = (state: iProfessionalFields = initialState, action: ProfessionalFieldsAction) => {
  switch (action.type) {
    case eProfessionalFields.FETCH_PROFESSIONAL_FIELDS_REQUEST:
      return {...state, loading: true, error: null};
    case eProfessionalFields.FETCH_PROFESSIONAL_FIELDS_SUCCESS:
      return {...state, loading: false, professionalFields: action.payload, error: null};
    case eProfessionalFields.FETCH_PROFESSIONAL_FIELDS_FAILURE:
      return {...state, loading: false, error: action.payload};

    default:
      return {...state};
  }
}

export default reducer;