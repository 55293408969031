import {DistrictsAction, eDistricts} from "./index";

const initialState: any = {
  districts: [],
  loading: false,
  error: null
}

const reducer = (state = initialState, action: DistrictsAction) => {
  switch (action.type) {
    case eDistricts.FETCH_DISTRICTS_REQUEST:
      return {...state, loading: true, error: null};
    case eDistricts.FETCH_DISTRICTS_SUCCESS:
      return {...state, loading: false, districts: action.payload, error: null};
    case eDistricts.FETCH_DISTRICTS_FAILURE:
      return {...state, loading: false, error: action.payload};

    default:
      return {...state};
  }
}

export default reducer;