
export enum EKeySkills {
  GET_KEY_SKILLS_SUCCESS = "GET_KEY_SKILLS_SUCCESS",
  GET_KEY_SKILLS_LOADING = "GET_KEY_SKILLS_LOADING",
  GET_KEY_SKILLS_FAILURE = "GET_KEY_SKILLS_FAILURE"
}

export interface iKeySkills {
  keySkills: {
    id: number
    name: string
  }[]
  loading: boolean
  error: any
}

interface fetchSuccess {
  type: EKeySkills.GET_KEY_SKILLS_SUCCESS
  payload: {
    id: number
    name: string
  }[]
}

interface fetchLoading {
  type: EKeySkills.GET_KEY_SKILLS_LOADING
}

interface fetchFailure {
  type: EKeySkills.GET_KEY_SKILLS_FAILURE
  payload: any
}

export type KeySkillsAction = fetchFailure | fetchLoading | fetchSuccess