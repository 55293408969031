export interface iRecommendedVacancy {
  id: number,
  is_applied: boolean,
  publication_date: string,
  position: string,
  vacancy_name: string,
  city: {
    id: number,
    name: string,
  },
  employer: {
    id: number,
    position: string,
    user: number,
    organization: {
      id: number,
      organization_name: string,
      organization_description: string,
      organization_city: string,
      organization_professional_field: number,
      organization_branches: number[],
      address: string,
      site_address: string,
      logo: string,
      active_vacancies_amount: number
    }
  },
  description: string,
  salary_from: number,
  salary_to: number,
  salary_currency: string,
  negotiated_salary: boolean,
  schedule: {
    id: number,
    name: string
  },
  employment_type: {
    id: number,
    name: string
  },
  experience: boolean,
  experience_amount: {
    id: number,
    value: string
  },
  responsibilities: string,
  requirements: string,
  conditions: string,
  languages: {
    language: {
      id: number,
      name: string
    },
    proficiency: {
      id: number,
      name: string
    }
  }[]
  responses_amount: number,
  new_responses_amount: number,
}

export interface iRecommendedVacancies {
  recommendedVacancies: iRecommendedVacancy[],
  error: any,
  loading: boolean
}

export enum eRecommendedVacancies {
  GET_RECOMMENDED_VACANCIES_SUCCESS = "GET_RECOMMENDED_VACANCIES_SUCCESS",
  GET_RECOMMENDED_VACANCIES_FAILURE = "GET_RECOMMENDED_VACANCIES_FAILURE",
  GET_RECOMMENDED_VACANCIES_REQUEST = "GET_RECOMMENDED_VACANCIES_REQUEST"
}

interface fetchSuccess {
  type: eRecommendedVacancies.GET_RECOMMENDED_VACANCIES_SUCCESS,
  payload: iRecommendedVacancy[]
}

interface fetchFailure {
  type: eRecommendedVacancies.GET_RECOMMENDED_VACANCIES_FAILURE,
  payload: any
}

interface fetchRequest {
  type: eRecommendedVacancies.GET_RECOMMENDED_VACANCIES_REQUEST
}

export type recommendedVacanciesAction = fetchSuccess | fetchFailure | fetchRequest