import {eOrganization, OrganizationActions} from "./index";

const initialState = {
  organization: {
    id: 0,
    organization_name: '',
    organization_description: '',
    organization_city: 0,
    organization_professional_field: 0,
    address: 'string',
    site_address: '',
    logo: '',
    is_moderated: false
  },
  loading: false,
  error: null
}

const reducer = (state = initialState, action: OrganizationActions) => {
  switch (action.type) {
    case eOrganization.CREATE_ORGANIZATION_REQUEST:
      return {...state, loading: true, error: null};
    case eOrganization.CREATE_ORGANIZATION_SUCCESS:
      return {...state, loading: false, error: null};
    case eOrganization.CREATE_ORGANIZATION_FAILURE:
      return {...state, loading: false, error: action.payload}

    case eOrganization.FETCH_ORGANIZATION_REQUEST:
      return {...state, loading: true, error: null};
    case eOrganization.FETCH_ORGANIZATION_SUCCESS:
      return {...state, loading: false, organization: action.payload, error: null};
    case eOrganization.FETCH_ORGANIZATION_FAILURE:
      return {...state, loading: false, error: action.payload};

    case eOrganization.UPDATE_ORGANIZATION_REQUEST:
      return {...state, loading: true, error: null};
    case eOrganization.UPDATE_ORGANIZATION_SUCCESS:
      return {...state, loading: false, error: null};
    case eOrganization.UPDATE_ORGANIZATION_FAILURE:
      return {...state, loading: false, error: action.payload};

    default:
      return {...state};
  }
}

export default reducer;