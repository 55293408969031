export interface iPosition {
  id: number,
  name: string
}

export interface iPositions {
  positions: iPosition[],
  loading: boolean,
  error: any
}

export interface iError {
  error: any
}

export enum ePositions {
  FETCH_POSITIONS_REQUEST = 'FETCH_POSITIONS_REQUEST',
  FETCH_POSITIONS_SUCCESS = 'FETCH_POSITIONS_SUCCESS',
  FETCH_POSITIONS_FAILURE = 'FETCH_POSITIONS_FAILURE'
}

interface fetchPositionsRequest {
  type: ePositions.FETCH_POSITIONS_REQUEST
}

interface fetchPositionsSuccess {
  type: ePositions.FETCH_POSITIONS_SUCCESS,
  payload: iPosition[]
}

interface fetchPositionsFailure {
  type: ePositions.FETCH_POSITIONS_FAILURE,
  payload: iError
}

export type PositionsActions =
  fetchPositionsRequest |
  fetchPositionsSuccess |
  fetchPositionsFailure