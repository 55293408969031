export interface iMyVacancyResponses {
  id: number;
  vacancy: iVacancy | iVacancy[]
  job_seeker: number;
  cover_letter: string,
  created_at: string;
  seen: boolean;
  invite: boolean;
}

interface iVacancy {
  id: number;
  is_moderated: boolean;
  on_editing: boolean;
  publication_date: string;
  position: string;
  vacancy_name: string;
  is_closed: boolean;
  employer: {
    id: number;
    position: string;
    user: number;
    organization: {
      id: number;
      organization_name: string;
      organization_description: string;
      organization_city: string;
      organization_professional_field: number;
      organization_branches: number[];
      address: string;
      site_address: string;
      logo: string;
      active_vacancies_amount: number;
    }
  };
  description: string;
  salary_from: number;
  salary_to: number;
  salary_currency: string;
  negotiated_salary: boolean;
  schedule: {
    id: number;
    name: string;
  };
  employment_type: {
    id: number;
    name: string;
  };
  experience: boolean;
  experience_amount: {
    id: number
    value: string;
  };
  responsibilities: string;
  requirements: string;
  conditions: string;
  languages: {
    language: {
      id: number;
      name: string;
    };
    proficiency: string;
  }[];
  responses_amount: number;
  new_responses_amount: number;
  invitations_amount: number;
  city: {
    id: number;
    name: string;
  }
}

export interface iAppliedVacancy {
  invitationsAndVacancyResponses: boolean
}

export enum eAppliedVacancy {
  INVITATIONS_AND_VACANCY_RESPONSES = "INVITATIONS_AND_VACANCY_RESPONSES"
}

interface iAppliedVacancyAction {
  type: eAppliedVacancy.INVITATIONS_AND_VACANCY_RESPONSES,
  payload: boolean
}

export type AppliedVacancyAction = iAppliedVacancyAction