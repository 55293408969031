export interface iWorkExperience {
  id: number,
  value: string
}

export interface iWorkExperiences {
  workExperiences: iWorkExperience[],
  loading: boolean,
  error: any
}

export interface iError {
  error: any
}

export enum eWorkExperiences {
  FETCH_WORK_EXPERIENCES_REQUEST = 'FETCH_WORK_EXPERIENCES_REQUEST',
  FETCH_WORK_EXPERIENCES_SUCCESS = 'FETCH_WORK_EXPERIENCES_SUCCESS',
  FETCH_WORK_EXPERIENCES_FAILURE = 'FETCH_WORK_EXPERIENCES_FAILURE'
}

interface fetchWorkExperiencesRequest {
  type: eWorkExperiences.FETCH_WORK_EXPERIENCES_REQUEST
}

interface fetchWorkExperiencesSuccess {
  type: eWorkExperiences.FETCH_WORK_EXPERIENCES_SUCCESS,
  payload: iWorkExperience[]
}

interface fetchWorkExperiencesFailure {
  type: eWorkExperiences.FETCH_WORK_EXPERIENCES_FAILURE,
  payload: iError
}

export type WorkExperiencesActions =
  fetchWorkExperiencesRequest |
  fetchWorkExperiencesSuccess |
  fetchWorkExperiencesFailure