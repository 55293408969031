import {eTrainings, iTrainingReducer, trainingsAction} from "./index";

const initialState: iTrainingReducer = {
  training: null,
  trainings: [],
  loading: false,
  error: null
}

const reducer = (state: iTrainingReducer = initialState, action: trainingsAction) => {
  switch (action.type) {
    case eTrainings.GET_TRAININGS_REQUEST:
      return {...state, loading: true}
    case eTrainings.GET_TRAININGS_DETAIL_SUCCESS:
      return {...state, loading: false, training: action.payload}
    case eTrainings.GET_TRAININGS_SUCCESS:
      return {...state, loading: false, trainings: action.payload}
    case eTrainings.GET_TRAININGS_FAILURE:
      return {...state, loading: false, error: action.payload}
    default:
      return state
  }
}

export default reducer