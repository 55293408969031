import {eAppliedVacancy, iAppliedVacancy, AppliedVacancyAction} from "./index";


const initialState: iAppliedVacancy = {
  invitationsAndVacancyResponses: false
}

const reducers = (state: iAppliedVacancy = initialState, action: AppliedVacancyAction) => {
  switch (action.type) {
    case eAppliedVacancy.INVITATIONS_AND_VACANCY_RESPONSES:
      return {...state, invitationsAndVacancyResponses: action.payload}
    default:
      return state
  }
}

export default reducers