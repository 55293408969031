import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import citiesReducer from '../redux/Cities/reducers';
import districtsReducer from '../redux/Districts/reducers';
import resumeReducer from '../redux/Resume/reducers';
import citizenShipsReducer from '../redux/CitizenShips/reducers';
import employmentTypesReducer from '../redux/EmploymentTypes/reducers';
import professionalFieldsReducer from '../redux/ProfessionalFields/reducers';
import workSchedulesReducer from '../redux/WorkSchedules/reducers';
import positionsReducer from '../redux/Positions/reducers';
import vacancyReducer from '../redux/Vacancies/reducers';
import organizationReducer from '../redux/Organization/reducers';
import workExperiencesReducer from '../redux/WorkExperience/reducers';
import languagesReducer from "../redux/Language/reducers";
import degreesReducer from "../redux/Degrees/reducers";
import keySkillsReducer from "../redux/KeySkills/reducer";
import vacancyResponsesReducer from "../redux/VacancyResponses/reducers";
import invitationsReducers from "../redux/Invitations/reducers";
import languageProficiencyLevelsReducer from "../redux/LanguagesProficiencyLevels/reducers";
import trainingsReducer from "../redux/Trainings/reducer";
import recommendedVacanciesReducer from "./RecommendedVacancies/reducers";
import appliedVacancyReducer from "./MyVacancyResponses/reducers";
import userPropsReducer from "./UserProps/reducers";

export const configStore = configureStore({
  reducer: {
    cities: citiesReducer,
    districts: districtsReducer,
    // @ts-ignore
    resume: resumeReducer,
    // @ts-ignore
    citizenShips: citizenShipsReducer,
    // @ts-ignore
    employmentTypes: employmentTypesReducer,
    // @ts-ignore
    professionalFields: professionalFieldsReducer,
    // @ts-ignore
    workSchedules: workSchedulesReducer,
    // @ts-ignore
    positions: positionsReducer,
    // @ts-ignore
    vacancy: vacancyReducer,
    // @ts-ignore
    organization: organizationReducer,
    workExperiences: workExperiencesReducer,
    languages: languagesReducer,
    degrees: degreesReducer,
    keySkills: keySkillsReducer,
    // @ts-ignore
    vacancyResponses: vacancyResponsesReducer,
    invitations: invitationsReducers,
    languageProficiencyLevels: languageProficiencyLevelsReducer,
    trainings: trainingsReducer,
    recommendedVacancies: recommendedVacanciesReducer,
    //@ts-ignore
    appliedVacancy: appliedVacancyReducer,
    //@ts-ignore
    userProps: userPropsReducer
  },
});

export type AppDispatch = typeof configStore.dispatch;
export type RootState = ReturnType<typeof configStore.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
