export interface iEmploymentType {
  id: number,
  name: string
}

export interface iEmplomentTypes {
  employmentTypes: iEmploymentType[],
  loading: boolean,
  error: any
}

export interface iError {
  error: any
}

export enum eEmploymentTypes {
  FETCH_EMPLOYMENT_TYPES_REQUEST = 'FETCH_EMPLOYMENT_TYPES_REQUEST',
  FETCH_EMPLOYMENT_TYPES_SUCCESS = 'FETCH_EMPLOYMENT_TYPES_SUCCESS',
  FETCH_EMPLOYMENT_TYPES_FAILURE = 'FETCH_EMPLOYMENT_TYPES_FAILURE'
}

interface fetchEmploymentTypesRequest {
  type: eEmploymentTypes.FETCH_EMPLOYMENT_TYPES_REQUEST
}

interface fetchEmploymentTypesSuccess {
  type: eEmploymentTypes.FETCH_EMPLOYMENT_TYPES_SUCCESS,
  payload: iEmploymentType[]
}

interface fetchEmploymentTypesFailure {
  type: eEmploymentTypes.FETCH_EMPLOYMENT_TYPES_FAILURE,
  payload: iError
}

export type EmploymentTypesAction =
  fetchEmploymentTypesRequest |
  fetchEmploymentTypesSuccess |
  fetchEmploymentTypesFailure