export interface iProfessionalField {
  id: number,
  name: string
}

export interface iProfessionalFields {
  professionalFields: iProfessionalField[],
  loading: boolean,
  error: any
}

export interface iError {
  error: any
}

export enum eProfessionalFields {
  FETCH_PROFESSIONAL_FIELDS_REQUEST = 'FETCH_PROFESSIONAL_FIELDS_REQUEST',
  FETCH_PROFESSIONAL_FIELDS_SUCCESS = 'FETCH_PROFESSIONAL_FIELDS_SUCCESS',
  FETCH_PROFESSIONAL_FIELDS_FAILURE = 'FETCH_PROFESSIONAL_FIELDS_FAILURE'
}

interface fetchProfessionalFieldsRequest {
  type: eProfessionalFields.FETCH_PROFESSIONAL_FIELDS_REQUEST
}

interface fetchProfessionalFieldsSuccess {
  type: eProfessionalFields.FETCH_PROFESSIONAL_FIELDS_SUCCESS,
  payload: iProfessionalField[]
}

interface fetchProfessionalFieldsFailure {
  type: eProfessionalFields.FETCH_PROFESSIONAL_FIELDS_FAILURE,
  payload: iError
}

export type ProfessionalFieldsAction =
  fetchProfessionalFieldsRequest |
  fetchProfessionalFieldsSuccess |
  fetchProfessionalFieldsFailure

