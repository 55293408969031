import {eResume, iResumes, resumeActions, iResumeFilters} from "./index";

const initialState: iResumes = {
  resume: {
    id: 0,
    first_name: '',
    last_name: '',
    patronymic: '',
    email: '',
    phone_number: '',
    birth_date: '',
    gender: 'male',
    has_experience: false,
    username: "",
    side_job: true,
    citizenship: {
      id: 0,
      value: ''
    },
    city: {
      id: 0,
      name: '',
    },
    housing_estate: {
      id: 0,
      name: '',
    },
    job_seeker: 0,
    professional_fields: [
      {
        id: 0,
        name: ''
      },
    ],
    schedule: {
      id: 0,
      name: ''
    },
    employment_type: {
      id: 0,
      name: ''
    },
    salary: 0,
    career_objective: [],
    previous_jobs: [
      {
        id: 0,
        job: {
          id: 0,
          organization: '',
          position: '',
          responsibilities: '',
        },
        date_from: '',
        date_to: ''
      },
    ],
    education: [
      {
        education: {
          degree: '',
          institution: '',
          faculty: '',
          specialization: ''
        },
        graduation_year: 0
      },
    ],
    comment: '',
    picture: '',
    summary: '',
    native_language: '',
    languages: [
      {
        language: {
          id: 0,
          name: ''
        },
        proficiency: {
          id: 0,
          name: ''
        }
      },
    ],
    is_invited: false,
    is_moderated: false,
    full_name: '',
    publication_date: '' || null,
    key_skills: [],
    poll: {
      question_1: false,
      question_2: false,
      question_3: false
    },
    invitation_vacancies: [],
    telegram_id: 0
  },
  resumes: [],
  resumeFilters: {
    search: '',
    city: '0',
    housingEstates: '0',
    position: '0',
    workExperience: '0'
  },
  loading: false,
  // @ts-ignore
  error: null
}

const reducer = (state: iResumes = initialState, action: resumeActions) => {
  switch (action.type) {
    case eResume.FETCH_RESUME_REQUEST:
      return {...state, loading: true, error: null};
    case eResume.FETCH_RESUME_SUCCESS:
      return {...state, loading: false, resume: action.payload, error: null};
    case eResume.FETCH_RESUME_FAILURE:
      return {...state, loading: false, error: action.payload};

    case eResume.CREATE_RESUME_REQUEST:
      return {...state, loading: true, error: null};
    case eResume.CREATE_RESUME_SUCCESS:
      return {...state, loading: false, error: null};
    case eResume.CREATE_RESUME_FAILURE:
      return {...state, loading: false, error: action.payload};

    case eResume.UPDATE_RESUME_REQUEST:
      return {...state, loading: true, error: null};
    case eResume.UPDATE_RESUME_SUCCESS:
      return {...state, loading: false, error: null};
    case eResume.UPDATE_RESUME_FAILURE:
      return {...state, loading: true, error: action.payload};

    case eResume.FETCH_ALL_RESUMES_REQUEST:
      return {...state, loading: true, error: null};
    case eResume.FETCH_ALL_RESUMES_SUCCESS:
      return {...state, loading: false, resumes: action.payload, error: null};
    case eResume.FETCH_ALL_RESUMES_FAILURE:
      return {...state, loading: false, error: action.payload};

    case eResume.FETCH_RESUME_BY_ID_REQUEST:
      return {...state, loading: true, error: null};
    case eResume.FETCH_RESUME_BY_ID_SUCCESS:
      return {...state, loading: false, resume: action.payload, error: null};
    case eResume.FETCH_RESUME_BY_ID_FAILURE:
      return {...state, loading: false, error: action.payload};

    case eResume.INVITE_FOR_AN_INTERVIEW_REQUEST:
      return {...state, loading: true, error: null};
    case eResume.INVITE_FOR_AN_INTERVIEW_SUCCESS:
      return {...state, loading: false, error: null};
    case eResume.INVITE_FOR_AN_INTERVIEW_FAILURE:
      return {...state, loading: false, error: action.payload};

    case eResume.FETCH_RESUMES_RESPONDED_TO_VACANCY_REQUEST:
      return {...state, loading: true, error: null};
    case eResume.FETCH_RESUMES_RESPONDED_TO_VACANCY_SUCCESS:
      return {...state, loading: false, resumes: action.payload, error: null};
    case eResume.FETCH_RESUMES_RESPONDED_TO_VACANCY_FAILURE:
      return {...state, loading: false, error: action.payload};

    case eResume.INVITE_FOR_AN_INTERVIEW_FROM_RESPONSE_REQUEST:
      return {...state, loading: true, error: null};
    case eResume.INVITE_FOR_AN_INTERVIEW_FROM_RESPONSE_SUCCESS:
      return {...state, loading: false, error: null};
    case eResume.INVITE_FOR_AN_INTERVIEW_FROM_RESPONSE_FAILURE:
      return {...state, loading: false, error: action.payload};

    case eResume.FETCH_RECOMMENDED_RESUMES_TO_VACANCY_REQUEST:
      return {...state, loading: true, error: null};
    case eResume.FETCH_RECOMMENDED_RESUMES_TO_VACANCY_SUCCESS:
      return {...state, loading: false, resumes: action.payload, error: null};
    case eResume.FETCH_RECOMMENDED_RESUMES_TO_VACANCY_FAILURE:
      return {...state, loading: false, error: action.payload};

    case eResume.DOWNLOAD_RESUME_PDF_REQUEST:
      return {...state, loading: false, error: null};
    case eResume.DOWNLOAD_RESUME_PDF_SUCCESS:
      return {...state, loading: false, error: null};
    case eResume.DOWNLOAD_RESUME_PDF_FAILURE:
      return {...state, loading: false, error: action.payload};

    case eResume.DOWNLOAD_RESUME_WORD_REQUEST:
      return {...state, loading: false, error: null};
    case eResume.DOWNLOAD_RESUME_WORD_SUCCESS:
      return {...state, loading: false, error: null};
    case eResume.DOWNLOAD_RESUME_WORD_FAILURE:
      return {...state, loading: false, error: action.payload};

    case eResume.SET_DOWNLOADED_RESUMES_SUCCESS:
      return {...state, resumes: action.payload};

    case eResume.SET_RESUME_FILTERS_SUCCESS:
      return {...state, resumeFilters: action.payload};

    default:
      return {...state};
  }
}

export default reducer;