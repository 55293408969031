export interface iWorkSchedule {
  id: number,
  name: string
}

export interface iWorkSchedules {
  workSchedules: iWorkSchedule[],
  loading: boolean,
  error: any
}

export interface iError {
  error: any
}

export enum eWorkSchedules {
  FETCH_WORK_SCHEDULES_REQUEST = 'FETCH_WORK_SCHEDULES_REQUEST',
  FETCH_WORK_SCHEDULES_SUCCESS = 'FETCH_WORK_SCHEDULES_SUCCESS',
  FETCH_WORK_SCHEDULES_FAILURE = 'FETCH_WORK_SCHEDULES_FAILURE'
}

interface fetchWorkSchedulesRequest {
  type: eWorkSchedules.FETCH_WORK_SCHEDULES_REQUEST
}

interface fetchWorkSchedulesSuccess {
  type: eWorkSchedules.FETCH_WORK_SCHEDULES_SUCCESS,
  payload: iWorkSchedule[]
}

interface fetchWorkSchedulesFailure {
  type: eWorkSchedules.FETCH_WORK_SCHEDULES_FAILURE,
  payload: iError
}

export type WorkSchedulesActions =
  fetchWorkSchedulesRequest |
  fetchWorkSchedulesSuccess |
  fetchWorkSchedulesFailure
