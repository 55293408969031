export interface iCity {
  id: number,
  name: string
}

export interface iCities {
  cities: iCity[]
  error: any
  loading: boolean
}

export interface iError {
  error: any
}

export enum eCities {
  FETCH_CITIES_REQUEST = 'FETCH_CITIES_REQUEST',
  FETCH_CITIES_SUCCESS = 'FETCH_CITIES_SUCCESS',
  FETCH_CITIES_FAILURE = 'FETCH_CITIES_FAILURE'
}

interface fetchCitiesRequest {
  type: eCities.FETCH_CITIES_REQUEST
}

interface fetchCitiesSuccess {
  type: eCities.FETCH_CITIES_SUCCESS,
  payload: iCities[]
}

interface fetchCitiesFailure {
  type: eCities.FETCH_CITIES_FAILURE,
  payload: iError
}

export type CitiesAction =
  fetchCitiesRequest |
  fetchCitiesSuccess |
  fetchCitiesFailure