export interface IDistrict {
  id: number
  name: string
}

export interface IDistricts {
  districts: IDistrict[]
  error: any
  loading: boolean
}

export interface iError {
  error: any
}

export enum eDistricts {
  FETCH_DISTRICTS_REQUEST = 'FETCH_DISTRICTS_REQUEST',
  FETCH_DISTRICTS_SUCCESS = 'FETCH_DISTRICTS_SUCCESS',
  FETCH_DISTRICTS_FAILURE = 'FETCH_DISTRICTS_FAILURE'
}

interface fetchDistrictsRequest {
  type: eDistricts.FETCH_DISTRICTS_REQUEST
}

interface fetchDistrictsSuccess {
  type: eDistricts.FETCH_DISTRICTS_SUCCESS,
  payload: IDistrict[]
}

interface fetchDistrictsFailure {
  type: eDistricts.FETCH_DISTRICTS_FAILURE,
  payload: iError
}

export type DistrictsAction =
  fetchDistrictsRequest |
  fetchDistrictsSuccess |
  fetchDistrictsFailure