export interface iResume {
  id: number,
  first_name: string,
  last_name: string,
  username: string,
  patronymic: string,
  email: string,
  has_experience: boolean,
  phone_number: string,
  side_job: boolean,
  birth_date: string,
  gender: "male" | "female",
  citizenship: {
    id: number,
    value: string
  },
  city: {
    id: number,
    name: string,
  },
  housing_estate: {
    id: number,
    name: string,
  },
  job_seeker: any,
  professional_fields: [
    {
      id: number,
      name: string
    },
  ]
  schedule: {
    id: number,
    name: string
  },
  employment_type: {
    id: number,
    name: string
  },
  salary: number,
  career_objective: any[],
  previous_jobs: iPreviousJob[],
  education: {
    education: {
      degree: string,
      institution: string,
      faculty: string,
      specialization: string
    },
    graduation_year: number
  }[],
  comment: string,
  picture: string,
  summary: string,
  native_language: any,
  languages: [
    {
      language: {
        id: number,
        name: string
      },
      proficiency: {
        id: number,
        name: string
      }
    },
  ],
  is_invited: boolean,
  is_moderated: boolean,
  full_name: string,
  publication_date: any,
  key_skills: {id: number, name: string}[],
  poll: {
    question_1: boolean,
    question_2: boolean,
    question_3: boolean,
  },
  invitation_vacancies: number[]
  telegram_id: number
}

export interface iPreviousJob {
  id: number,
  job: {
    id: number,
    organization: string,
    position: string,
    responsibilities: string,
  },
  date_from: string,
  date_to: string
}

export interface iError {
  error: any
}

export interface iResumeFilters {
  search: string,
  city: string;
  housingEstates: string;
  position: string;
  workExperience: string;
}

export interface iResumes {
  resume: iResume;
  resumes: iResume[];
  resumeFilters: iResumeFilters;
  loading: boolean;
  error: iError;
}

export enum eResume {
  FETCH_RESUME_REQUEST = 'FETCH_RESUME_REQUEST',
  FETCH_RESUME_SUCCESS = 'FETCH_RESUME_SUCCESS',
  FETCH_RESUME_FAILURE = 'FETCH_RESUME_FAILURE',

  CREATE_RESUME_REQUEST = 'CREATE_RESUME_REQUEST',
  CREATE_RESUME_SUCCESS = 'CREATE_RESUME_SUCCESS',
  CREATE_RESUME_FAILURE = 'CREATE_RESUME_FAILURE',

  UPDATE_RESUME_REQUEST = 'UPDATE_RESUME_REQUEST',
  UPDATE_RESUME_SUCCESS = 'UPDATE_RESUME_SUCCESS',
  UPDATE_RESUME_FAILURE = 'UPDATE_RESUME_FAILURE',

  FETCH_ALL_RESUMES_REQUEST = 'FETCH_ALL_RESUMES_REQUEST',
  FETCH_ALL_RESUMES_SUCCESS = 'FETCH_ALL_RESUMES_SUCCESS',
  FETCH_ALL_RESUMES_FAILURE = 'FETCH_ALL_RESUMES_FAILURE',

  FETCH_RESUME_BY_ID_REQUEST = 'FETCH_RESUME_BY_ID_REQUEST',
  FETCH_RESUME_BY_ID_SUCCESS = 'FETCH_RESUME_BY_ID_SUCCESS',
  FETCH_RESUME_BY_ID_FAILURE = 'FETCH_RESUME_BY_ID_FAILURE',

  INVITE_FOR_AN_INTERVIEW_REQUEST = 'INVITE_FOR_AN_INTERVIEW_REQUEST',
  INVITE_FOR_AN_INTERVIEW_SUCCESS = 'INVITE_FOR_AN_INTERVIEW_SUCCESS',
  INVITE_FOR_AN_INTERVIEW_FAILURE = 'INVITE_FOR_AN_INTERVIEW_FAILURE',

  FETCH_RESUMES_RESPONDED_TO_VACANCY_REQUEST = 'FETCH_RESUMES_RESPONDED_TO_VACANCY_REQUEST',
  FETCH_RESUMES_RESPONDED_TO_VACANCY_SUCCESS = 'FETCH_RESUMES_RESPONDED_TO_VACANCY_SUCCESS',
  FETCH_RESUMES_RESPONDED_TO_VACANCY_FAILURE = 'FETCH_RESUMES_RESPONDED_TO_VACANCY_FAILURE',

  INVITE_FOR_AN_INTERVIEW_FROM_RESPONSE_REQUEST = 'INVITE_FOR_AN_INTERVIEW_FROM_RESPONSE_REQUEST',
  INVITE_FOR_AN_INTERVIEW_FROM_RESPONSE_SUCCESS = 'INVITE_FOR_AN_INTERVIEW_FROM_RESPONSE_SUCCESS',
  INVITE_FOR_AN_INTERVIEW_FROM_RESPONSE_FAILURE = 'INVITE_FOR_AN_INTERVIEW_FROM_RESPONSE_FAILURE',

  FETCH_RECOMMENDED_RESUMES_TO_VACANCY_REQUEST = 'FETCH_RECOMMENDED_RESUMES_TO_VACANCY_REQUEST',
  FETCH_RECOMMENDED_RESUMES_TO_VACANCY_SUCCESS = 'FETCH_RECOMMENDED_RESUMES_TO_VACANCY_SUCCESS',
  FETCH_RECOMMENDED_RESUMES_TO_VACANCY_FAILURE = 'FETCH_RECOMMENDED_RESUMES_TO_VACANCY_FAILURE',

  DOWNLOAD_RESUME_PDF_REQUEST = 'DOWNLOAD_RESUME_PDF_REQUEST',
  DOWNLOAD_RESUME_PDF_SUCCESS = 'DOWNLOAD_RESUME_PDF_SUCCESS',
  DOWNLOAD_RESUME_PDF_FAILURE = 'DOWNLOAD_RESUME_PDF_FAILURE',

  DOWNLOAD_RESUME_WORD_REQUEST = 'DOWNLOAD_RESUME_WORD_REQUEST',
  DOWNLOAD_RESUME_WORD_SUCCESS = 'DOWNLOAD_RESUME_WORD_SUCCESS',
  DOWNLOAD_RESUME_WORD_FAILURE = 'DOWNLOAD_RESUME_WORD_FAILURE',

  SET_DOWNLOADED_RESUMES_SUCCESS = 'SET_DOWNLOADED_RESUMES_SUCCESS',

  SET_RESUME_FILTERS_SUCCESS = 'SET_RESUME_FILTERS_SUCCESS',
}

interface fetchResumeRequest {
  type: eResume.FETCH_RESUME_REQUEST;
}

interface fetchResumeSuccess {
  type: eResume.FETCH_RESUME_SUCCESS,
  payload: iResume
}

interface fetchResumeFailure {
  type: eResume.FETCH_RESUME_FAILURE,
  payload: iError
}

interface createResumeRequest {
  type: eResume.CREATE_RESUME_REQUEST
}

interface createResumeSuccess {
  type: eResume.CREATE_RESUME_SUCCESS
}

interface createResumeFailure {
  type: eResume.CREATE_RESUME_FAILURE,
  payload: iError
}

interface updateResumeRequest {
  type: eResume.UPDATE_RESUME_REQUEST
}

interface updateResumeSuccess {
  type: eResume.UPDATE_RESUME_SUCCESS
}

interface updateResumeFailure {
  type: eResume.UPDATE_RESUME_FAILURE,
  payload: iError
}

interface fetchAllResumesRequest {
  type: eResume.FETCH_ALL_RESUMES_REQUEST
}

interface fetchAllResumesSuccess {
  type: eResume.FETCH_ALL_RESUMES_SUCCESS,
  payload: iResume[]
}

interface fetchAllResumesFailure {
  type: eResume.FETCH_ALL_RESUMES_FAILURE,
  payload: iError
}

interface fetchResumeByIdRequest {
  type: eResume.FETCH_RESUME_BY_ID_REQUEST
}

interface fetchResumeByIdSuccess {
  type: eResume.FETCH_RESUME_BY_ID_SUCCESS,
  payload: iResume
}

interface fetchResumeByIdFailure {
  type: eResume.FETCH_RESUME_BY_ID_FAILURE,
  payload: iError
}

interface inviteForAnInterviewRequest {
  type: eResume.INVITE_FOR_AN_INTERVIEW_REQUEST
}

interface inviteForAnInterviewSuccess {
  type: eResume.INVITE_FOR_AN_INTERVIEW_SUCCESS
}

interface inviteForAnInterviewFailure {
  type: eResume.INVITE_FOR_AN_INTERVIEW_FAILURE,
  payload: iError
}

interface fetchResumesRespondedToVacancyRequest {
  type: eResume.FETCH_RESUMES_RESPONDED_TO_VACANCY_REQUEST
}

interface fetchResumesRespondedToVacancySuccess {
  type: eResume.FETCH_RESUMES_RESPONDED_TO_VACANCY_SUCCESS,
  payload: iResume[]
}

interface fetchResumesRespondedToVacancyFailure {
  type: eResume.FETCH_RESUMES_RESPONDED_TO_VACANCY_FAILURE,
  payload: iError
}

interface inviteForAnInterviewFromResponseRequest {
  type: eResume.INVITE_FOR_AN_INTERVIEW_FROM_RESPONSE_REQUEST
}

interface inviteForAnInterviewFromResponseSuccess {
  type: eResume.INVITE_FOR_AN_INTERVIEW_FROM_RESPONSE_SUCCESS
}

interface inviteForAnInterviewFromResponseFailure {
  type: eResume.INVITE_FOR_AN_INTERVIEW_FROM_RESPONSE_FAILURE,
  payload: iError
}

interface fetchRecommendedResumesToVacancyRequest {
  type: eResume.FETCH_RECOMMENDED_RESUMES_TO_VACANCY_REQUEST
}

interface fetchRecommendedResumesToVacancySuccess {
  type: eResume.FETCH_RECOMMENDED_RESUMES_TO_VACANCY_SUCCESS,
  payload: iResume[]
}

interface fetchRecommendedResumesToVacancyFailure {
  type: eResume.FETCH_RECOMMENDED_RESUMES_TO_VACANCY_FAILURE,
  payload: iError
}

interface downloadResumePdfRequest {
  type: eResume.DOWNLOAD_RESUME_PDF_REQUEST
}

interface downloadResumePdfSuccess {
  type: eResume.DOWNLOAD_RESUME_PDF_SUCCESS
}

interface downloadResumePdfFailure {
  type: eResume.DOWNLOAD_RESUME_PDF_FAILURE,
  payload: iError
}

interface downloadResumeWordRequest {
  type: eResume.DOWNLOAD_RESUME_WORD_REQUEST
}

interface downloadResumeWordSuccess {
  type: eResume.DOWNLOAD_RESUME_WORD_SUCCESS
}

interface downloadResumeWordFailure {
  type: eResume.DOWNLOAD_RESUME_WORD_FAILURE,
  payload: iError
}

interface setDownloadedResumes {
  type: eResume.SET_DOWNLOADED_RESUMES_SUCCESS,
  payload: iResume[]
}

interface setResumeFilters {
  type: eResume.SET_RESUME_FILTERS_SUCCESS,
  payload: iResumeFilters
}

export type resumeActions =
  fetchResumeRequest |
  fetchResumeSuccess |
  fetchResumeFailure |
  createResumeRequest |
  createResumeSuccess |
  createResumeFailure |
  updateResumeRequest |
  updateResumeSuccess |
  updateResumeFailure |
  fetchAllResumesRequest |
  fetchAllResumesSuccess |
  fetchAllResumesFailure |
  fetchResumeByIdRequest |
  fetchResumeByIdSuccess |
  fetchResumeByIdFailure |
  inviteForAnInterviewRequest |
  inviteForAnInterviewSuccess |
  inviteForAnInterviewFailure |
  fetchResumesRespondedToVacancyRequest |
  fetchResumesRespondedToVacancySuccess |
  fetchResumesRespondedToVacancyFailure |
  inviteForAnInterviewFromResponseRequest |
  inviteForAnInterviewFromResponseSuccess |
  inviteForAnInterviewFromResponseFailure |
  fetchRecommendedResumesToVacancyRequest |
  fetchRecommendedResumesToVacancySuccess |
  fetchRecommendedResumesToVacancyFailure |
  downloadResumePdfRequest |
  downloadResumePdfSuccess |
  downloadResumePdfFailure |
  downloadResumeWordRequest |
  downloadResumeWordSuccess |
  downloadResumeWordFailure |
  setDownloadedResumes |
  setResumeFilters