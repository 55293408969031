import {eWorkSchedules, iWorkSchedules, WorkSchedulesActions} from "./index";

const initialState: iWorkSchedules = {
  workSchedules: [],
  loading: false,
  error: null
}

const reducer = (state: iWorkSchedules = initialState, action: WorkSchedulesActions) => {
  switch (action.type) {
    case eWorkSchedules.FETCH_WORK_SCHEDULES_REQUEST:
      return {...state, loading: true, error: null};
    case eWorkSchedules.FETCH_WORK_SCHEDULES_SUCCESS:
      return {...state, loading: false, workSchedules: action.payload, error: null};
    case eWorkSchedules.FETCH_WORK_SCHEDULES_FAILURE:
      return {...state, loading: false, error: action.payload};

    default:
      return {...state};
  }
}

export default reducer;