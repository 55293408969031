export const REGIS_PER_ROLE = 'regis_per_role';
export const RUS_LANG = 'rus_lang';
export const KG_LANG = 'kg_lang';
export const COMPANIES_CREATED = 'companies_created';
export const VACANCIES_CREATED = 'vacancies_created';
export const SEEKER_VIEWED = 'seeker_viewed';
export const CV_VIEWED = 'cv_viewed';
export const SEEKER_INVITED = 'seeker_invited';
export const APPLIED = 'applied';
export const VAC_VIEWED = 'vac_viewed';
export const VAC_DELETE = 'vac_delete';
export const SUGGESTED = 'suggested';
export const TONEWS_O = 'tonews_o';
export const TONEWS_U = 'tonews_u';
export const CV_CREATED = 'cv_created';
export const CV_CHANGE = 'cv_change';
export const MODERATION_ACCEPT = 'moderation_accept';
export const MODERATION_DECLINE = 'moderation_decline';
export const EMPLOYEE_FOUND = 'employee_found';
export const EMPLOYEE_NOT_FOUND = 'employee_not_found';
export const EMPLOYEE_FOUND_OTHER = 'employee_found_other';