import React, {useEffect} from 'react';
import {Routes, Route, useLocation, useNavigate, useSearchParams} from 'react-router-dom';

import i18n from "../i18n";
import {fAnalytics} from "../firebase";
import routes, {getName} from '../_routes';
import useAfterRender from "../hooks/useAfterRender";

const DefaultLayout = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    let query = new URLSearchParams(window.location.search);
    let locale = query.get('lang');

    if (!query.get('lang')) {
      setSearchParams(searchParams => {
        searchParams.set('lang', locale || i18n.language);
        return searchParams;
      })
    }

    if (typeof locale === 'string') {
      localStorage.setItem('locale', locale || '');
      i18n.changeLanguage(locale).then();
    }
  }, [searchParams, setSearchParams]);

  useAfterRender(() => {
    const pageName = getName(location.pathname);
    if (pageName) fAnalytics.event('page_view', {page_title: pageName});
  })

  const months = [
    {month: 'Январь', id: 1},
    {month: 'Февраль', id: 2},
    {month: 'Март', id: 3},
    {month: 'Апрель', id: 4},
    {month: 'Май', id: 5},
    {month: 'Июнь', id: 6},
    {month: 'Июль', id: 7},
    {month: 'Август', id: 8},
    {month: 'Сентябрь', id: 9},
    {month: 'Октябрь', id: 10},
    {month: 'Ноябрь', id: 11},
    {month: 'Декабрь', id: 12}
  ]

  return (
    <React.Suspense fallback={null}>
      <div className="layout-wrapper">
        <Routes>
          {routes.map(route => {
            return (
              // @ts-ignore
              <Route key={route.name + '-route'} exact={route.exact}
                     path={route.path}
                     name={route.name}
                     element={
                       <route.element months={months}
                                      location={location}
                                      name={route.name}
                                      navigate={navigate}
                                      lang={localStorage.getItem('locale') || i18n.language}
                       />
                     }
              />
            )
          })}
        </Routes>
      </div>
    </React.Suspense>
  );
};

export default DefaultLayout;