import {ePositions, iPositions, PositionsActions} from "./index";

const initialState: iPositions = {
  positions: [],
  loading: false,
  error: null
}

const reducer = (state: iPositions = initialState, action: PositionsActions) => {
  switch (action.type) {
    case ePositions.FETCH_POSITIONS_REQUEST:
      return {...state, loading: true, error: null};
    case ePositions.FETCH_POSITIONS_SUCCESS:
      return {...state, loading: false, positions: action.payload, error: null};
    case ePositions.FETCH_POSITIONS_FAILURE:
      return {...state, loading: false, error: action.payload};

    default:
      return {...state};
  }
}

export default reducer;