import {eVacancies, iVacancies, VacanciesActions} from "./index";

const initialState: iVacancies = {
  vacancy: {
    id: 0,
    is_applied: false,
    position: {
      id: 0,
      name: ''
    },
    vacancy_name: '',
    city: {
      id: 0,
      name: '',
    },
    employer: {
      id: 0,
      position: '',
      user: 0,
      organization: {
        id: 0,
        organization_name: '',
        organization_description: '',
        organization_city: 0
      }
    },
    description: '',
    salary: 0,
    schedule: {
      id: 0,
      name: ''
    },
    employment_type: {
      id: 0,
      name: ''
    },
    professional_fields: [],
    experience: false,
    responsibilities: '',
    requirements: '',
    conditions: '',
    responses_amount: 0,
    is_moderated: false,
    on_editing: false,
    publication_date: null,
    salary_from: 0,
    salary_to: 0,
    experience_amount: {
      id: 0,
      value: ""
    },
    new_responses_amount: 0,
    invitations_amount: 0,
    negotiated_salary: false,
    recommended_cvs_amount: 0,
    is_closed: false
  },
  vacancies: [],
  vacanciesFilters: {
    filterSearchValue: '',
    city: '0',
    schedule: '0',
    position: '0',
    salary: '0',
    search: ''
  },
  loading: false,
  error: null
}

const reducer = (state: iVacancies = initialState, action: VacanciesActions) => {
  switch (action.type) {
    case eVacancies.FETCH_VACANCY_BY_ID_REQUEST:
      return {...state, loading: true, error: null};
    case eVacancies.FETCH_VACANCY_BY_ID_SUCCESS:
      return {...state, loading: false, vacancy: action.payload, error: null};
    case eVacancies.FETCH_VACANCY_BY_ID_FAILURE:
      return {...state, loading: false, error: action.payload};

    case eVacancies.FETCH_VACANCIES_REQUEST:
      return {...state, loading: true, error: null};
    case eVacancies.FETCH_VACANCIES_SUCCESS:
      return {...state, loading: false, vacancies: action.payload, error: null};
    case eVacancies.FETCH_VACANCIES_FAILURE:
      return {...state, loading: false, error: action.payload};
    case eVacancies.RESET_VACANCIES:
      return {...state, loading: false, vacancies: action.payload, error: null};

    case eVacancies.FETCH_VACANCIES_BY_AUTHUID_REQUEST:
      return {...state, loading: true, error: null};
    case eVacancies.FETCH_VACANCIES_BY_AUTHUID_SUCCESS:
      return {...state, loading: false, vacancies: action.payload, error: null};
    case eVacancies.FETCH_VACANCIES_BY_AUTHUID_FAILURE:
      return {...state, loading: false, error: action.payload};

    case eVacancies.CREATE_VACANCY_REQUEST:
      return {...state, loading: true, error: null};
    case eVacancies.CREATE_VACANCY_SUCCESS:
      return {...state, loading: false, error: null};
    case eVacancies.CREATE_VACANCY_FAILURE:
      return {...state, loading: false, error: action.payload};

    case eVacancies.UPDATE_VACANCY_REQUEST:
      return {...state, loading: true, error: null};
    case eVacancies.UPDATE_VACANCY_SUCCESS:
      return {...state, loading: false, error: null};
    case eVacancies.UPDATE_VACANCY_FAILURE:
      return {...state, loading: false, error: action.payload};

    case eVacancies.APPLY_VACANCY_REQUEST:
      return {...state, loading: true, error: null};
    case eVacancies.APPLY_VACANCY_SUCCESS:
      return {...state, loading: false, error: null};
    case eVacancies.APPLY_VACANCY_FAILURE:
      return {...state, loading: false, error: action.payload};

    case eVacancies.DELETE_VACANCY_REQUEST:
      return {...state, loading: true, error: null};
    case eVacancies.DELETE_VACANCY_SUCCESS:
      return {...state, loading: false, error: null};
    case eVacancies.DELETE_VACANCY_FAILURE:
      return {...state, loading: false, error: action.payload};

    case eVacancies.SEND_VACANCY_RESPONSE_SEEN_REQUEST:
      return {...state, loading: true, error: null};
    case eVacancies.SEND_VACANCY_RESPONSE_SEEN_SUCCESS:
      return {...state, loading: false, error: null};
    case eVacancies.SEND_VACANCY_RESPONSE_SEEN_FAILURE:
      return {...state, loading: false, error: action.payload};

    case eVacancies.SET_DOWNLOADED_VACANCIES_SUCCESS:
      return {...state, vacancies: action.payload};

    case eVacancies.SET_VACANCIES_FILTERS_SUCCESS:
      return {...state, vacanciesFilters: action.payload};

    default:
      return {...state};
  }
}

export default reducer;