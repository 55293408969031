import {CitiesAction, eCities, iCities} from './index';

const initialState: any = {
  cities: [],
  loading: false,
  error: null
}

const reducer = (state = initialState, action: CitiesAction) => {
  switch (action.type) {
    case eCities.FETCH_CITIES_REQUEST:
      return {...state, loading: true, error: null};
    case eCities.FETCH_CITIES_SUCCESS:
      return {...state, loading: false, cities: action.payload, error: null};
    case eCities.FETCH_CITIES_FAILURE:
      return {...state, loading: false, error: action.payload};

    default:
      return {...state};
  }
}

export default reducer;