import {CitizenChipsAction, eCitizenShips, iCitizenShips} from "./index";

const initialState: iCitizenShips = {
  citizenShips: [],
  loading: false,
  error: null
}

const reducer = (state: iCitizenShips = initialState, action: CitizenChipsAction) => {
  switch (action.type) {
    case eCitizenShips.FETCH_CITIZENSHIPS_REQUEST:
      return {...state, loading: true, error: null};
    case eCitizenShips.FETCH_CITIZENSHIPS_SUCCESS:
      return {...state, loading: false, citizenShips: action.payload, error: null}
    case eCitizenShips.FETCH_CITIZENSHIPS_FAILURE:
      return {...state, loading: false, error: action.payload}

    default:
      return {...state};
  }
}

export default reducer;