import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import "dayjs/locale/ru";

import * as types from './firebaseEventTypes'
import i18n from "./i18n";
import axios from "./axios";
import {fAnalytics} from "./firebase";
import {iUserProps} from "./redux/UserProps";
import {BrowserRouter} from "react-router-dom";
import {useAppDispatch} from "./app/hooks";
import {setUserProps} from "./redux/UserProps/actions";
import DefaultLayout from "./Layout/DefaultLayout";
import useAfterRender from "./hooks/useAfterRender";

const App = () => {
  const dispatch = useAppDispatch();

  const [isAuthError, setIsAuthError] = useState<string>('');

  useEffect(() => {
    let query = new URLSearchParams(window.location.search);
    let locale = query.get('lang');
    if (typeof locale === 'string') {
      localStorage.setItem('locale', locale || '');
      i18n.changeLanguage(locale).then();
    }
  }, []);

  useAfterRender(() => {
    let query = new URLSearchParams(window.location.search);
    let locale = query.get('lang');
    let uid = query.get('uid');
    let role = query.get('role');

    let data: iUserProps = {
      language: locale || '',
      user_role: role || '',
    };

    axios.get('get-me').then(res => {
      data.language = res.data.language_code || '';
      data.user_role = res.data.user_type;
      data.telegram_name = res.data.username;
      data.telegram_id = res.data.telegram_id;
      data.joopbar_id = res.data.id;
      if (res.data?.employer?.hasOwnProperty('city')) data.city = res.data.employer.city;
      if (res.data?.employer?.hasOwnProperty('region')) data.region = res.data.employer.region;
      if (res.data?.employer?.hasOwnProperty('organization_name')) data.company_name = res.data.employer.organization_name;

      if (res.data?.job_seeker?.hasOwnProperty('age')) data.age = res.data.job_seeker.age;
      if (res.data?.job_seeker?.hasOwnProperty('city')) data.city = res.data.job_seeker.city;
      if (res.data?.job_seeker?.hasOwnProperty("gender")) data.gender = res.data?.job_seeker?.gender;
      if (res.data?.job_seeker?.hasOwnProperty('region')) data.region = res.data.job_seeker.region;
      if (res.data?.job_seeker?.hasOwnProperty("is_neet")) data.neet = String(!!res.data?.job_seeker?.is_neet);
      if (res.data?.job_seeker?.hasOwnProperty('full_name')) data.full_name = res.data.job_seeker.full_name;
      if (res.data?.job_seeker?.hasOwnProperty('birth_date')) data.birth_date = res.data?.job_seeker?.birth_date
      if (res.data?.job_seeker?.hasOwnProperty('phone_number')) data.phone_number = res.data.job_seeker.phone_number;
      if (res.data?.first_name) data.first_name = res.data.first_name;
      if (res.data?.last_name) data.last_name = res.data.last_name;

      dispatch(setUserProps(data));

      fAnalytics.setUserProps(data);
    }).catch(error => {
      setIsAuthError(error.data || 'Unauthorized');
    })

    if (window?.location?.search.length) {
      if (typeof uid === 'string') {
        localStorage.setItem('authUid', uid);
      }

      fAnalytics.event(types.REGIS_PER_ROLE, {
        user_role: role,
        phone_number: data.phone_number ? data.phone_number : '',
        telegram_name: data.telegram_name,
        telegram_id: data.telegram_id,
        joopbar_id: data.joopbar_id,
        city: data.city
      })
      if (locale === 'ky') fAnalytics.event(types.KG_LANG, locale);
      if (locale === 'ru') fAnalytics.event(types.RUS_LANG, locale);
    }
    window?.Telegram?.WebApp?.expand();
  })

  // if (isAuthError) return (
  //   <div style={{padding: '25px 15px'}}>
  //     <h1>401: Unauthorized</h1>
  //     <h4>{isAuthError}</h4>
  //   </div>
  // );

  return (
    <BrowserRouter>
      <DefaultLayout />
    </BrowserRouter>
  );
};

export default withTranslation()(App);