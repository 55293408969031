import {LanguageAction, eLanguage, iLanguages} from './index';

const initialState: iLanguages = {
  languages: [],
  loading: false,
  error: null
}

const reducer = (state: iLanguages = initialState, action: LanguageAction) => {
  switch (action.type) {
    case eLanguage.FETCH_LANGUAGE_REQUEST:
      return {...state, loading: true, error: null};
    case eLanguage.FETCH_LANGUAGE_SUCCESS:
      return {...state, loading: false, languages: action.payload, error: null};
    case eLanguage.FETCH_LANGUAGE_FAILURE:
      return {...state, loading: false, error: action.payload};

    default:
      return {...state};
  }
}

export default reducer;