import {DegreeAction, eDegree, iDegrees} from "./index";

const initialState: iDegrees = {
  degrees: [],
  loading: false,
  error: null
}

const reducer = (state: iDegrees = initialState, action: DegreeAction) => {
  switch (action.type) {
    case eDegree.FETCH_DEGREE_REQUEST:
      return {...state, loading: true, error: null};
    case eDegree.FETCH_DEGREE_SUCCESS:
      return {...state, loading: false, degrees: action.payload, error: null};
    case eDegree.FETCH_DEGREE_FAILURE:
      return {...state, loading: false, error: action.payload};
    default:
      return {...state};
  }
}

export default reducer;