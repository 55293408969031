import {eLanguageProficiencyLevels, iLanguageProficiencyLevels, languageProficiencyLevelsAction} from "./index";

const initialState: iLanguageProficiencyLevels = {
  language: [],
  error: null,
  loading: false
}

const reducer = (state: iLanguageProficiencyLevels = initialState, action: languageProficiencyLevelsAction) => {
  switch (action.type) {
    case eLanguageProficiencyLevels.GET_LANGUAGE_PROFICIENCY_LEVELS_LOADING:
      return {...state, loading: true}
    case eLanguageProficiencyLevels.GET_LANGUAGE_PROFICIENCY_LEVELS_FAILURE:
      return {...state, loading: false, error: action.payload}
    case eLanguageProficiencyLevels.GET_LANGUAGE_PROFICIENCY_LEVELS_SUCCESS:
      return {...state, loading: false, language: action.payload}
    default:
      return state
  }
}

export default reducer