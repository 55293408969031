import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { configStore } from './redux/configStore';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './scss/style.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.css';
import './i18n'

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={configStore}>
      <App />
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
